import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export function animateAllArrows() {
  const arrowContainers = document.querySelectorAll('.hive-arrow-container');

  arrowContainers.forEach(container => {
    const arrow1 = container.querySelector('.hive-arrow-1');
    const arrow2 = container.querySelector('.hive-arrow-2');

    const tl = gsap.timeline({ repeat: -1, yoyo: true });

    tl.to([arrow1, arrow2], {
      y: 5,
      duration: 1,
      ease: 'sine.inOut',
    })
      .to([arrow1, arrow2], {
        y: 0,
        duration: 1,
        ease: 'sine.inOut',
      });

    container.addEventListener('click', () => {
      const currentContainer = container.closest('.md-container');
      let nextContainer = currentContainer.nextElementSibling;

      while (nextContainer && !nextContainer.matches('.md-container')) {
        nextContainer = nextContainer.nextElementSibling;
      }

      console.log(nextContainer.offsetTop);
      if (nextContainer) {
        gsap.to(window, {
          scrollTo: { y: nextContainer.offsetTop, autoKill: true , offsetY: 100 },
          duration: 1.4,
          ease: 'power4.inOut'
        });
      }
    });
  });
}

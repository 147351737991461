import { gsap } from 'gsap';

export function animateMarquee(element, duration = 10) {
  if (!element) return;

  const originalText = element.innerHTML;
  element.innerHTML = originalText + originalText + originalText;

  gsap.to(element, {
    x: () => -(element.offsetWidth / 3),
    duration: duration,
    ease: "linear",
    repeat: -1,
    modifiers: {
      x: gsap.utils.unitize(x => parseFloat(x) % (element.offsetWidth / 3))
    }
  });
}
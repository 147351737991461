import animateTitle from './animateTitle.js';
import testimonySlider from './testimonySlider.js';
import { animateAllArrows } from './animateArrow.js';
import { animateMarquee } from './animateMarquee.js';

document.addEventListener('DOMContentLoaded', () => {
  const animatedContainers = document.getElementsByClassName('md-animated-title-container');

  if (animatedContainers) {
    Array.from(animatedContainers).forEach(container => {
      const dataAnimated = container.dataset.animated;
      const animatedTitle = container.querySelector('.animated-hero-animated');
      const separator = container.querySelector('.title-separator');
      let height = 50;
      let loop = false;

      if (container.dataset.animatedHeight) {
        height = container.dataset.animatedHeight;
      }

      if (container.dataset.animatedLoop) {
        loop = container.dataset.animatedLoop;
      }

      if (dataAnimated) {
        animateTitle(container, animatedTitle, separator, {
          height: height,
          loop: loop,
        });
      }
    });
  }

  animateAllArrows();

  const animatedMarqueeElements = document.getElementsByClassName('md-animated-marquee');

  if (!!animatedMarqueeElements) {
    Array.from(animatedMarqueeElements).forEach(element => {
      animateMarquee(element);
    });
  }

  const slider = testimonySlider();

  slider.initSlider();
});

import { gsap } from 'gsap';

export default function animateTitle(container, animatedTitle, separator = null, options = {
  height: 25,
  loop: false,
}) {
  if (!animatedTitle) return;

  const words = JSON.parse(animatedTitle.dataset.content);
  const defaultWord = animatedTitle.dataset.default;

  animatedTitle.removeAttribute('data-content');
  animatedTitle.removeAttribute('data-default');

  const updateContainerClassesForResponsiveDesign = (isLastWord = false) => {
    if (window.innerWidth < 575) {
      if (isLastWord) {
        animatedTitle.classList.remove('d-block', 'w-100');
        animatedTitle.classList.add('d-inline-block');
      } else {
        animatedTitle.classList.add('d-block', 'w-100');
        animatedTitle.classList.remove('d-inline-block');
      }
    } else {
      animatedTitle.classList.remove('d-inline-block', 'd-block', 'w-100');
    }
  };

  updateContainerClassesForResponsiveDesign();

  const animateAppearance = (word, onComplete = () => {}, duration = 0.3, isLastWord = false) => {
    const wordSpan = document.createElement('span');
    wordSpan.textContent = word;
    wordSpan.style.display = 'inline-block';
    animatedTitle.textContent = '';
    animatedTitle.appendChild(wordSpan);

    gsap.from(wordSpan, {
      opacity: 0,
      y: options.height,
      duration: duration,
      ease: 'power4.out',
      onComplete: () => onComplete(wordSpan),
    });
  };

  const animateDisappearance = (wordSpan, onComplete) => {
    gsap.to(wordSpan, {
      opacity: 0,
      y: -options.height,
      duration: 0.3,
      ease: 'power4.in',
      onComplete: () => {
        animatedTitle.removeChild(wordSpan);
        if (onComplete) onComplete();
      },
    });
  };

  const animateWords = (index = 0) => {
    if (index < words.length) {
      animateAppearance(words[index], (wordSpan) => {
        animateDisappearance(wordSpan, () => {
          animateWords(index + 1);
        });
      });
    } else {
      updateContainerClassesForResponsiveDesign(true);
      if (!!separator) {
        gsap.to(separator, {
          opacity: 0,
          duration: 0.5,
          ease: 'power4.in',
          onComplete: () => {
            animateAppearance(defaultWord, () => {}, 0.5, true);
          },
        });
      } else {
        animateAppearance(defaultWord, () => {}, 0.3, true);
      }
    }
  };

  animateWords();
}

import Swiper from 'swiper';
import { Grid, Pagination, Autoplay } from 'swiper/modules';

export default function testimonySlider() {
  const $swiper = $('.md-testimony-slider-container');

  const init = () => {
    if ($swiper.length > 0) {
      $swiper.each(function () {
        const el = $(this);
        const [component] = el.find('.swiper');

        new Swiper(component, {
          modules: [Grid, Pagination, Autoplay],
          slidesPerView: 3,
          grabCursor: true,
          loop: true,
          speed: 800,
          autoplay: {
            delay: 2000,
            pauseOnMouseEnter: true,
          },
          spaceBetween: 0,
          pagination: {
            el: el.find('.swiper-pagination')[0],
            clickable: true,
          },
          breakpoints: {
            0: {
              slidesPerView: 1.1,
              spaceBetween: 16,
              grid: {
                rows: 1,
              },
            },
            575: {
              slidesPerView: 1.4,
              spaceBetween: 16,
              grid: {
                rows: 1,
              },
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 0,
              grid: {
                rows: 2,
              },
            },
          }
        });
      });
    }
  };

  return {
    initSlider: init,
  };
}
